<template>
  <div class="row">
    <!-- begin::Overview -->
    <div class="col-12">
      <div class="card card-custom">
        <!-- begin::Header -->
        <div class="card-header flex-wrap">
          <div class="card-title">
            <h3 class="card-label">
              Overview
              <span class="text-muted pt-2 font-size-sm d-block">Sunbeds that belong to this company.</span>
            </h3>
          </div>

          <div class="card-toolbar">
            <button
              v-if="$auth.hasPermission('companies.equipment.connect')"
              class="btn btn-primary font-weight-bolder mr-3"
              @click.prevent="connectSunbed()"
            >
              <span class="svg-icon align-middle">
                <font-awesome-icon :icon="['fas', 'link']" />
              </span>
              <span>Connect sunbed</span>
            </button>

            <router-link
              v-if="$auth.hasPermission('equipment.create')"
              to="/sunbeds/create"
              class="btn btn-primary font-weight-bolder"
            >
              <span class="svg-icon align-middle">
                <font-awesome-icon :icon="['fas', 'plus']" />
              </span>
              <span>Add sunbed</span>
            </router-link>
          </div>
        </div>
        <!-- end::Header -->

        <!-- begin::Body -->
        <div class="card-body">
          <!-- begin::Datatable actions -->
          <div class="mb-7">
            <div class="row align-items-center">
              <div class="col-12 col-lg">
                <div class="input-icon">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    @keyup.enter="$refs.datatable.search"
                    @keyup="searchDebounced"
                  >
                  <span>
                    <i>
                      <font-awesome-icon :icon="['fas', 'search']" />
                    </i>
                  </span>
                </div>
              </div>

              <div class="col-12 mt-3 col-lg-auto mt-lg-0">
                <overview-filters
                  style="min-width: 150px;"
                  class="btn-block"

                  @apply="onFilters"
                />
              </div>
            </div>
          </div>
          <!-- end::Datatable actions -->

          <!-- begin::Datatable -->
          <datatable
            ref="datatable"
            :url="datatable.url"
            :fields="datatable.fields"
            :filters="datatable.filters"
            :search-fields="datatable.searchFields"
            :on-pagination-data="onPaginationData"

            :transform="transform"
          >
            <!-- begin::Status -->
            <template slot="status" slot-scope="props">
              <div
                v-for="(label, idx) in [getStateLabel(props.rowData)]"
                :key="`equipment.${props.rowData._meta.guid}.state.${idx}`"
                :class="['label w-100 label-inline label-xl label-rounded p5', `label-${label && label.variant || 'secondary'}`]"
                v-html="label && label.text || 'Unknown'"
              />
            </template>
            <!-- end::Status -->

            <!-- begin::Nickname -->
            <template slot="nickname" slot-scope="props">
              <router-link
                :class="['text-info', { 'font-italic': !props.rowData.alias }]"
                :to="`/sunbeds/view/${props.rowData._meta.guid}`"
              >
                {{ (props.rowData.alias || 'No nickname set') }}
              </router-link>
            </template>
            <!-- end::Nickname -->

            <!-- begin::Serial -->
            <template
              slot="serial"
              slot-scope="props"
            >
              <router-link
                class="text-info"
                :to="`/sunbeds/view/${props.rowData._meta.guid}`"
              >
                {{ props.rowData.serial }}
              </router-link>
            </template>
            <!-- end::Serial -->

            <!-- begin::Module -->
            <template slot="module" slot-scope="props">
              <div :class="['label w-100 label-inline label-xl label-rounded p-5', (props.rowData.isDeviceLinked() === true ? 'label-light-info' : 'label-light-warning')]">
                {{ (props.rowData.isDeviceLinked() === true ? props.rowData.device.serial : 'Not linked') }}
              </div>
            </template>
            <!-- end::Module -->

            <!-- begin::Actions -->
            <template slot="actions" slot-scope="props">
              <!-- begin::Information dropdown -->
              <information-dropdown
                :key="`equipment.${props.rowData._meta.guid}.actions.information`"
                :equipment="props.rowData"
              />
              <!-- end::Information dropdown -->
            </template>
            <!-- end::Actions -->
          </datatable>
          <!-- end::Datatable -->

          <!-- begin::Datatable footer -->
          <div class="datatable datatable-default">
            <div class="datatable-pager datatable-paging-loaded">
              <datatable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              />

              <datatable-pagination-info
                ref="pagination.info"
                @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
              />
            </div>
          </div>
          <!-- end::Datatable footer -->
        </div>
        <!-- end::Body -->
      </div>
    </div>
    <!-- end::Overview -->

    <!-- begin::Modals -->
    <connect-sunbed-modal v-if="$auth.hasPermission('companies.equipment.connect')" />
    <!-- end::Modals -->
  </div>
</template>

<script>
import qs from 'qs';
import moment from 'moment-timezone';
import equipmentService from '@/libs/equipment';

import { DatatableMixin } from '@vedicium/metronic-vue';
import overviewFilters from '@/components/pages/sunbeds/components/overview-filters/index.vue';
import connectSunbedModal from '@/components/pages/sunbeds/modals/connect-sunbed/index.vue';
import informationDropdown from '@/components/pages/sunbeds/components/information-dropdown/dropdown.vue';

import Equipment from '@/libs/classes/equipment';

export default {
  mixins: [DatatableMixin],
  components: {
    overviewFilters,
    connectSunbedModal,
    informationDropdown,
  },
  data () {
    return {
      datatable: {
        url: Equipment.uri,

        fields: [{
          name: 'status',
          title: 'Status',
          sortField: 'state',
          titleClass: 'w-100px',
          dataClass: 'w-100px',
        }, {
          name: 'nickname',
          title: 'Nickname',
          sortField: 'alias',
          titleClass: 'w-200px',
          dataClass: 'w-200px',
        }, {
          name: 'serial',
          title: 'Serialnumber',
          sortField: 'serial',
          titleClass: 'w-200px',
          dataClass: 'w-200px',
        }, {
          name: 'model',
          title: 'Model',
          sortField: 'model',
          titleClass: 'w-100px',
          dataClass: 'w-100px',
          formatter: (value) => this.$tu(`sunbeds.models.${value}.name`) || value,
        }, {
          name: 'location.description',
          title: 'Location',
          sortField: 'location.description',
          titleClass: 'w-300px',
          dataClass: 'w-300px',
          formatter (value) {
            return `${value || '<span class="font-italic">No location set</span>'}`;
          },
        }, {
          name: 'module',
          title: 'Module',
          sortField: 'device.serial',
          titleClass: 'w-100px',
          dataClass: 'w-100px',
        }, {
          name: 'device.last_ping',
          title: 'Last seen',
          sortField: 'device.last_ping',
          titleClass: 'w-150px',
          dataClass: 'w-150px',
          formatter (value) {
            return (value ? moment(value).fromNow() : 'Never');
          },
        }, {
          name: 'actions',
          title: 'Actions',
          titleClass: 'w-100px',
          dataClass: 'w-100px',
        }],
        searchFields: ['serial', 'alias', 'location.description', 'device.serial'],

        filters: {},
      },
    };
  },
  watch: {
    async $route (route) {
      this.$set(this.datatable, 'filters', this.parseFilters(route.query));
      await this.$nextTick();
      this.$refs.datatable.refresh();
    },
  },

  beforeMount () {
    this.$set(this.datatable, 'filters', this.parseFilters(this.$route.query));
  },
  mounted () {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Sunbeds' }]);

    // Show 'connect sunbed' modal
    if (this.$auth.hasPermission('companies.equipment.connect')) {
      if (this.$route.query.link !== undefined) {
        const [serial, psk] = (typeof this.$route.query.link === 'string' && this.$route.query.link.length > 0 ? this.$route.query.link.split(':') : []);
        this.connectSunbed({ serial, psk });
        // routerLib.removeQueryFromRoute(this.$router, 'link');
      }
    }
  },

  methods: {
    transform (data) {
      const response = { ...data };
      if (response.data && Array.isArray(response.data)) {
        response.data = response.data.map((row) => new Equipment().merge(row));
      }
      return response;
    },
    getStateLabel (equipment = null) {
      return equipmentService.stateLabel(equipment);
    },

    parseFilters (query = {}) {
      // Add filters
      if (!query) {
        return null;
      }

      const parsedQuery = qs.parse(this.$route.query);
      if (!parsedQuery.filter) {
        return null;
      }

      return parsedQuery.filter;
    },

    connectSunbed (options = {}) {
      const vm = this;

      // Open 'Connect sunbed' modal
      vm.$eventhub.emit('modals:equipment:connect-sunbed:open', {
        serial: (options && options.serial) || null,
        psk: (options && options.psk) || null,

        async onSubmit () {
          vm.$refs.datatable.reload({ query: { disable_cache: true } });
        },
      });
    },

    async onFilters (filters = null) {
      if (!filters) {
        return;
      }

      // Copy current filters
      const datatableFilters = { ...this.datatable.filters };

      // Loop through every known status
      ['state', 'model', 'company_guid', 'software_version'].forEach((filter) => {
        const value = filters[(filter)];

        switch (filter) {
          case 'company_guid': {
            datatableFilters.company_guids = value;
            break;
          }

          case 'software_version': {
            datatableFilters['controller.release.hash'] = value;
            break;
          }

          default: {
            datatableFilters[(filter)] = value;
            break;
          }
        }
      });

      // Remove 'null' values
      Object.entries(datatableFilters).forEach(([key, value]) => {
        if (value === undefined || value === null) {
          delete datatableFilters[key];
        }
      });

      // Apply filters
      this.$set(this.datatable, 'filters', datatableFilters);
      await this.$nextTick();

      // Go back to page 1 if on other page than 1
      if (this.$refs.datatable.$refs.vuetable.currentPage !== 1) {
        this.$refs.datatable.$refs.vuetable.gotoPage(1);
      } else {
        this.$refs.datatable.reload();
      }
    },
  },
};
</script>
