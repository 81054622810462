/* eslint-disable lines-between-class-members */
import i18n from '@/i18n/index';
import moment from 'moment-timezone';

// import calculate from '@/libs/calculate';
import format from '@/libs/format';

import Equipment, { SunbedErrors } from '@/libs/classes/equipment';
import Device from '@/libs/classes/device';

/**
 * Max 32-bit integer used as 'Infinity' for equipment health
 * The 'Infinity' number is not supported by JSON, which causes many problems.
 */
export const EQUIPMENT_HEALTH_INFINITY_NUMBER = 2147483647;

export default class EquipmentService {
  static availableStates = ['stand-by', 'cooldown', 'cleaning', 'controller-powerup', 'controller-pincode', 'controller-config-view', 'controller-quickstatus-view', 'controller-setting-time', 'controller-warning', 'controller-confirm', 'controller-copy-files', 'controller-online-update', 'controller-online-parameter-change'];
  static inSessionStates = ['in-session', 'session-paused'];
  static attentionStates = ['unknown', 'offline', 'no-device', 'controller-error'];

  static stateLabel (equipment = null) {
    if (equipment instanceof Equipment === false) {
      return null;
    }

    const label = { variant: 'secondary', text: i18n.tu(`sunbeds.states.${equipment.state}.title`) || equipment.state };
    switch (true) {
      case ['controller-error', 'controller-timeout', 'no-device', 'offline'].includes(equipment.state):
        label.variant = 'light-danger';
        break;

      case ['controller-powerup', 'controller-config-view', 'controller-quickstatus-view', 'cooldown', 'controller-confirm', 'controller-copy-files', 'controller-online-update', 'controller-online-parameter-change'].includes(equipment.state):
        label.variant = 'light-info';
        break;

      case ['controller-pincode', 'controller-setting-time', 'cleaning'].includes(equipment.state):
        label.variant = 'secondary';
        break;

      case ['stand-by', 'controller-warning'].includes(equipment.state):
        label.variant = 'light-success';
        break;

      case ['in-session', 'session-paused'].includes(equipment.state):
        label.variant = 'light-warning';
        break;

      default:
        break;
    }

    return label;
  }

  static notifications (equipment = null, device = null, options = {}) {
    if (equipment instanceof Equipment === false || device instanceof Device === false) {
      return [];
    }

    // No module linked
    if (equipment.isDeviceLinked() === false || !device) {
      return [{
        type: 'info',
        icon: 'cube',
        text: 'No module linked',
        variant: 'info',
        route: {
          name: '/sunbeds/view/:guid/settings/module',
          query: { 'link-module': null },
        },
      }];
    }

    const notifications = [];

    // State notifications
    const controllerState = equipment.state;
    const controllerText = i18n.tu(`sunbeds.states.${controllerState}.description`) || `Unknown state (${controllerState})`;
    switch (equipment.state) {
      case 'offline':
        notifications.push({
          type: 'status',
          icon: 'wifi-slash',
          text: controllerText,
          variant: 'danger',
          route: null,
        });
        break;

      case 'no-device':
        notifications.push({
          type: 'status',
          icon: 'unlink',
          text: controllerText,
          variant: 'danger',
          route: null,
        });
        break;

      case 'controller-timeout':
        notifications.push({
          type: 'status',
          icon: 'question',
          text: controllerText,
          variant: 'danger',
          route: null,
        });
        break;

      case 'controller-powerup':
        notifications.push({
          type: 'status',
          icon: 'rocket',
          text: controllerText,
          variant: 'info',
          route: null,
        });
        break;

      case 'controller-pincode':
        notifications.push({
          type: 'status',
          icon: 'user-lock',
          text: controllerText,
          variant: 'dark',
          route: null,
        });
        break;

      case 'controller-config-view':
        notifications.push({
          type: 'status',
          icon: 'cogs',
          text: controllerText,
          variant: 'info',
          route: null,
        });
        break;

      case 'controller-quickstatus-view':
        notifications.push({
          type: 'status',
          icon: 'info-circle',
          text: controllerText,
          variant: 'info',
          route: null,
        });
        break;

      case 'controller-setting-time':
        notifications.push({
          type: 'status',
          icon: 'user-cog',
          text: controllerText,
          variant: 'dark',
          route: null,
        });
        break;

      case 'controller-warning':
        notifications.push({
          type: 'status',
          icon: 'user-shield',
          text: controllerText,
          variant: 'success',
          route: null,
        });
        break;

      case 'controller-error':
        notifications.push({
          type: 'status',
          icon: 'times-circle',
          text: controllerText,
          variant: 'danger',
          route: null,
        });
        break;

      case 'controller-confirm':
        notifications.push({
          type: 'status',
          icon: 'chevron-circle-right',
          text: controllerText,
          variant: 'info',
          route: null,
        });
        break;

      case 'controller-copy-files':
        notifications.push({
          type: 'status',
          icon: 'download',
          text: controllerText,
          variant: 'info',
          route: null,
        });
        break;

      case 'controller-online-update':
        notifications.push({
          type: 'status',
          icon: 'download',
          text: controllerText,
          variant: 'info',
          route: null,
        });
        break;

      case 'controller-online-parameter-change':
        notifications.push({
          type: 'status',
          icon: 'exchange-alt',
          text: controllerText,
          variant: 'info',
          route: null,
        });
        break;

      case 'stand-by':
        notifications.push({
          type: 'status',
          icon: 'check',
          text: controllerText,
          variant: 'success',
          route: null,
        });
        break;

      case 'in-session':
        notifications.push({
          type: 'status',
          icon: 'sun',
          text: controllerText,
          variant: 'warning',
          route: null,
        });
        break;

      case 'cooldown':
        notifications.push({
          type: 'status',
          icon: 'fan',
          text: controllerText,
          variant: 'info',
          spin: true,
          route: null,
        });
        break;

      case 'session-paused':
        notifications.push({
          type: 'status',
          icon: 'pause',
          text: controllerText,
          variant: 'warning',
          route: null,
        });
        break;

      case 'cleaning':
        notifications.push({
          type: 'status',
          icon: 'hand-sparkles',
          text: controllerText,
          variant: 'dark',
          route: null,
        });
        break;

      default:
        notifications.push({
          type: 'status',
          icon: 'question',
          text: controllerText,
          variant: 'dark',
          route: null,
        });
        break;
    }

    // Add extra notifications if equipment is not offline or in timeout
    if (['offline', 'controller-timeout'].includes(controllerState) === false) {
      // Add error notifications
      if (equipment.controller && equipment.controller.active_errors && equipment.controller.active_errors > 0) {
        const activeErrorBits = format.bitsToArray(equipment.controller.active_errors);
        (activeErrorBits || []).forEach((value, idx) => {
          if (value === 1) {
            const sunbedError = SunbedErrors.find((error) => error.bit === idx);

            // Set description of error
            let description = `${sunbedError ? sunbedError.error : `B${idx}`}`;

            // Get support URL of error
            const supportURL = i18n.tu(`luxura_logs.error.errors.${sunbedError.error}.support_url`, { equipment });
            if (supportURL) {
              description = `${description} - <a href="${supportURL}" target="_blank" class="text-brand">Click here for help fixing this error</a>`;
            }

            notifications.push({
              type: 'error',
              icon: (sunbedError && sunbedError.critical === true ? 'exclamation-circle' : 'exclamation-triangle'),
              text: (sunbedError ? (i18n.tu(`luxura_logs.error.errors.${sunbedError.error}.description`) || `Error ${sunbedError.error}`) : 'Unknown error'),
              description,
              variant: (sunbedError && sunbedError.critical === true ? 'danger' : 'warning'),
              route: null,
              payload: {
                critical: ((sunbedError && sunbedError.critical) || false),
              },
            });
          }
        });
      }

      // Add maintenance notification, if given
      if (options.maintenance && Array.isArray(options.maintenance)) {
        const currentDate = moment.utc();
        (options.maintenance || []).forEach((counter) => {
          // If current value is more than max, maintenance is due
          if (counter.value >= counter.max_value) {
            notifications.push({
              type: 'maintenance',
              icon: 'tools',
              text: `${i18n.tu(`sunbeds.models.${equipment.model}.maintenance.hours.${counter.counter}.name`) || counter.counter} maintenance due`,
              description: 'Perform maintenance as soon as possible.',
              variant: 'danger',
              route: `/sunbeds/view/${equipment._meta.guid}/health/maintenance`,
              payload: {
                counter: counter.counter,
              },
            });
          }

          // If current value is less than max, we've to check if maintenance is due in trigger window
          // Only if maintenance calculation is found
          if (counter.maintenance && counter.maintenance.due_date && counter.value < counter.max_value) {
            // By default, it's 14 days before maintenance is due
            let maintenanceDueDays = 14;

            // This is changed by the notification settings, if enabled
            if (counter.notification_settings && counter.notification_settings.maintenance_preventative && counter.notification_settings.maintenance_preventative.enabled === true) {
              maintenanceDueDays = counter.notification_settings.maintenance_preventative.days_before_due || maintenanceDueDays;
            }

            // Check if maintenance due date is equal to or less than current date
            // Add 1 so the current date is also added in the difference lookup
            const maintenanceDueDaysDifference = moment.utc(counter.maintenance.due_date).diff(currentDate, 'days') + 1;
            if (maintenanceDueDaysDifference <= maintenanceDueDays) {
              notifications.push({
                type: 'maintenance',
                icon: 'tools',
                text: `${i18n.tu(`sunbeds.models.${equipment.model}.maintenance.hours.${counter.counter}.name`) || counter.counter} maintenance due in ${maintenanceDueDaysDifference} ${i18n.tc('plural.days', maintenanceDueDaysDifference)}`,
                description: 'Please schedule maintenance and order required parts.',
                variant: 'warning',
                route: `/sunbeds/view/${equipment._meta.guid}/health/maintenance`,
                payload: {
                  counter: counter.counter,
                },
              });
            }
          }
        });
      }
    }

    return notifications;
  }
}
