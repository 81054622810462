var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('div',{staticClass:"row h-50px w-100"},[_vm._m(0)]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex align-items-center"},[_c('div',{class:[
      'symbol symbol-30 mr-3',
      (_vm.software.release && _vm.software.release.hash
        ? (_vm.software.latest && _vm.software.latest._meta.guid === _vm.software.release.hash)
          ? 'symbol-light-success'
          : 'symbol-light-info'
        : 'symbol-secondary'
      )
    ]},[_c('span',{staticClass:"symbol-label"},[_c('span',{staticClass:"svg-icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'cogs']}})],1)])]),_c('div',{staticClass:"d-flex flex-column font-weight-bold"},[_c('span',{staticClass:"text-dark text-hover-primary"},[_vm._v(" "+_vm._s((_vm.software.release && _vm.software.release.version) || 'Unknown')+" ")]),_c('span',{staticClass:"text-muted font-size-xs"},[_vm._v("Software version")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col loading-container loading-overlap"},[_c('div',{staticClass:"loading-backdrop rounded"}),_c('div',{staticClass:"loading-block"},[_c('div',{staticClass:"blockui mx-auto w-100"},[_c('span',[_vm._v("Gathering data...")]),_c('span',{staticClass:"spinner spinner-primary ml-auto"})])])])}]

export { render, staticRenderFns }