<template>
  <b-dropdown
    ref="dropdown"
    variant="outline-secondary"
    right
    no-caret
    no-flip
    lazy
    menu-class="dropdown-menu-lg px-8 py-5"

    @hide="onDropdownHide"
  >
    <!-- begin::Button -->
    <template v-slot:button-content>
      <i class="text-brand">
        <font-awesome-icon :icon="['fas', 'filter']" />
      </i>
      Filter
    </template>
    <!-- end::Button -->

    <!-- begin::Dropdown items -->
    <template v-slot:default>
      <form class="p-0" @submit.prevent>
        <div>
          <h5 class="text-dark">Filter options</h5>
        </div>

        <div class="separator separator-solid my-5" />

        <div>
          <!-- begin::Status filter -->
          <div class="mb-5">
            <label class="form-label h6 text-dark">Status</label>
            <v-select
              ref="select:status"

              class="form-control"
              placeholder="Please select"

              v-model="filters.state"

              :options="metadata.filters.state.options"
              :filter="metadata.filters.state.filter"

              @input="onSelectInput"
            >
              <!-- begin::Selected option -->
              <template slot="selected-option" slot-scope="option">
                <span v-text="$tu(`sunbeds.states.${option.label}.title`) || option.label" />
              </template>
              <!-- end::Selected option -->
              <!-- begin::Option -->
              <template slot="option" slot-scope="option">
                <span v-text="$tu(`sunbeds.states.${option.label}.title`) || option.label" />
              </template>
              <!-- end::Option -->
            </v-select>
          </div>
          <!-- end::Status filter -->

          <!-- begin::Model filter -->
          <div class="mb-5">
            <label class="form-label h6 text-dark">Model</label>
            <v-select
              ref="select:model"

              class="form-control"
              placeholder="Please select"

              v-model="filters.model"

              :options="metadata.filters.model.options"
              :filter="metadata.filters.model.filter"

              @input="onSelectInput"
            >
              <!-- begin::Selected option -->
              <template slot="selected-option" slot-scope="option">
                <span v-text="$tu(`sunbeds.models.${option.label}.name`) || option.label" />
              </template>
              <!-- end::Selected option -->
              <!-- begin::Option -->
              <template slot="option" slot-scope="option">
                <span v-text="$tu(`sunbeds.models.${option.label}.name`) || option.label" />
              </template>
              <!-- end::Option -->
            </v-select>
          </div>
          <!-- end::Model filter -->

          <!-- begin::Company filter -->
          <template v-if="$auth.hasPermission('companies.get')">
            <div class="mb-5">
              <label class="form-label h6 text-dark">Linked company</label>
              <v-select
                ref="select:company"

                class="form-control"
                placeholder="Search..."
                label="name"

                v-model="filters.company_guid"

                :options="metadata.filters.company.options"
                :reduce="(option) => option._meta.guid"
                :min-input-length="1"
                :filterable="false"

                @input="onSelectInput"
                @search="onLinkedCompanySearch"
              >
                <!-- begin::Selected option -->
                <template slot="selected-option" slot-scope="option">
                  <span v-text="option.name" />
                </template>
                <!-- end::Selected option -->
                <!-- begin::Option -->
                <template slot="option" slot-scope="option">
                  <span v-text="option.name" />
                </template>
                <!-- end::Option -->
              </v-select>
            </div>
          </template>
          <!-- end::Company filter -->

          <!-- begin::Software version filter -->
          <template v-if="$auth.hasPermission('updates.get')">
            <div class="mb-5">
              <label class="form-label h6 text-dark">Software version</label>
              <v-select
                ref="select:software-version"

                class="form-control"
                placeholder="Search..."
                label="version"

                v-model="filters.software_version"

                :options="metadata.filters.software_version.options"
                :reduce="(option) => option._meta.guid"
                :min-input-length="1"
                :filterable="false"

                @input="onSelectInput"
                @search="onSoftwareVersionSearch"
              >
                <!-- begin::Selected option -->
                <template slot="selected-option" slot-scope="option">
                  <span v-text="option.version" />
                </template>
                <!-- end::Selected option -->
                <!-- begin::Option -->
                <template slot="option" slot-scope="option">
                  <span v-text="option.version" />
                </template>
                <!-- end::Option -->
              </v-select>
            </div>
          </template>
          <!-- end::Software version filter -->
        </div>

        <div class="separator separator-solid my-5" />

        <div class="d-flex justify-content-end">
          <button type="button" class="btn btn-white btn-active-light-primary mr-2" @click.prevent="resetFilters">Reset</button>
          <button type="button" class="btn btn-light-info" @click.prevent="applyFilters">Apply</button>
        </div>
      </form>
    </template>
    <!-- end::Dropdown items -->
  </b-dropdown>
</template>

<style lang="scss">
@import '@/assets/sass/plugins/vue-select/index.scss';
</style>

<script>
import vSelect from '@bartversluijs/vue-select';
import { CompanyClass as Company } from '@vedicium/core-vue/build/modules/company';
import Update from '@/libs/classes/update';

export default {
  name: 'overviewFilters',
  components: {
    vSelect,
  },
  data () {
    return {
      isDropdownHideable: true,

      filters: {
        state: null,
        model: null,
        company_guid: null,
        software_version: null,
      },

      metadata: {
        filters: {
          state: {
            filter (options, search) {
              return options.filter((option) => {
                const values = [option];
                if (this.$te(`sunbeds.states.${option}.title`)) {
                  values.push(this.$t(`sunbeds.states.${option}.title`));
                }

                return values.some((value) => value.toLowerCase().includes(search.toLowerCase()));
              }, []);
            },

            options: [
              'offline',
              'no-device',
              'stand-by',
              'controller-error',
              'in-session',
              'controller-online-update',
              'controller-timeout',
            ],
          },

          model: {
            filter (options, search) {
              return options.filter((option) => {
                const values = [option];
                if (this.$te(`sunbeds.models.${option}.name`)) {
                  values.push(this.$t(`sunbeds.models.${option}.name`));
                }

                return values.some((value) => value.toLowerCase().includes(search.toLowerCase()));
              }, []);
            },

            options: [
              'luxura_jewel',
              'luxura_vegaz_9200',
              'luxura_vegaz_8200',
              'luxura_x7',
              'luxura_x5',
              'luxura_v8',
              'luxura_v6',
            ],
          },

          company: {
            options: [],
            timeout: null,
          },

          software_version: {
            options: [],
            timeout: null,
          },
        },
      },
    };
  },

  methods: {
    resetFilters () {
      this.$set(this.filters, 'state', null);
      this.$set(this.filters, 'model', null);
      this.$set(this.filters, 'company_guid', null);
      this.$set(this.filters, 'software_version', null);

      this.applyFilters();
    },

    applyFilters () {
      this.$emit('apply', { ...this.filters });

      this.$set(this, 'isDropdownHideable', true);
      this.$refs.dropdown.hide();
    },

    async onSelectInput () {
      // Disable the ability to hide the dropdown
      // This is because of issues when selection an option which is outside of the dropdown
      this.$set(this, 'isDropdownHideable', false);
      setTimeout(() => this.$set(this, 'isDropdownHideable', true), 400);
    },

    onDropdownHide (e) {
      if (this.isDropdownHideable === false) {
        e.preventDefault();
      }
    },

    // Company filter
    async fetchCompanies (query = null) {
      try {
        const response = await this.$ws.get(Company.uri, {
          query: {
            q: `*${query}*`, // Add wildcards to search
            search_fields: 'name',
            sort: 'name|asc',
          },
        });

        return response.map((row) => new Company().merge(row));
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { ui_element: true });
        throw e;
      }
    },

    onLinkedCompanySearch (query = null) {
      clearTimeout(this.metadata.filters.company.timeout);
      if (!query) {
        return;
      }

      this.$refs['select:company'].toggleLoading(true);
      this.$set(this.metadata.filters.company, 'timeout', setTimeout(async () => {
        this.$set(this.metadata.filters.company, 'options', await this.fetchCompanies(query));
        this.$refs['select:company'].toggleLoading(false);
      }, 600));
    },

    // Update filter
    async fetchUpdates (query = null) {
      try {
        const response = await this.$ws.get(Update.uri, {
          query: {
            q: `*${query}*`, // Add wildcards to search,
            search_fields: 'version',
            sort: '_meta.created|desc',
          },
        });

        return response.map((row) => new Update().merge(row));
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { ui_element: true });
        throw e;
      }
    },

    onSoftwareVersionSearch (query = null) {
      clearTimeout(this.metadata.filters.software_version.timeout);
      if (!query) {
        return;
      }

      this.$refs['select:software-version'].toggleLoading(true);
      this.$set(this.metadata.filters.software_version, 'timeout', setTimeout(async () => {
        this.$set(this.metadata.filters.software_version, 'options', await this.fetchUpdates(query));
        this.$refs['select:software-version'].toggleLoading(false);
      }, 600));
    },
  },
};
</script>
