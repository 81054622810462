<template>
  <b-dropdown
    v-if="equipment"
    ref="dropdown"
    variant="clean btn-sm btn-icon"
    class="dropdown-inline"
    right
    lazy
    no-caret
    boundary="window"
    menu-class="dropdown-menu-lg px-8 py-5"
  >
    <template v-slot:button-content>
      <span class="svg-icon">
        <font-awesome-icon :icon="['fas', 'info-circle']" />
      </span>
    </template>

    <template v-slot:default>
      <div class="row">
        <div class="col-12 pb-1 border-bottom">
          <span class="navi-header font-weight-bolder text-uppercase font-size-xs text-primary">Sunbed information</span>
        </div>

        <!-- begin::Sunbed -->
        <div class="col-12 mt-3 border-bottom pb-3">
          <div class="row">
             <!-- begin::Sunbed type -->
            <div class="col-12 d-flex align-items-center">
              <div class="symbol symbol-30 symbol-light-info mr-3">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-primary">
                    <font-awesome-icon :icon="['fas', 'info']" />
                  </span>
                </span>
              </div>
              <div class="d-flex flex-column font-weight-bold">
                <span class="text-dark text-hover-primary">{{ ($tu(`sunbeds.models.${equipment.model}.types.${equipment.type}`) || equipment.type || '-') }}</span>
                <span class="text-muted font-size-xs">Sunbed type</span>
              </div>
            </div>
            <!-- end::Sunbed type -->

            <!-- begin::Sunbed hours -->
            <div class="col-12 d-flex align-items-center mt-3">
              <div class="symbol symbol-30 symbol-light-info mr-3">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-primary">
                    <font-awesome-icon :icon="['fas', 'tint']" />
                  </span>
                </span>
              </div>
              <div class="d-flex flex-column font-weight-bold">
                <span class="text-dark text-hover-primary">{{ equipment.getColorOfLocation('sunbed') || '-' }}</span>
                <span class="text-muted font-size-xs">Sunbed color</span>
              </div>
            </div>
            <!-- end::Sunbed hours -->

            <!-- begin::Sunbed hours -->
            <div class="col-12 d-flex align-items-center mt-3">
              <div class="symbol symbol-30 symbol-light-info mr-3">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-primary">
                    <font-awesome-icon :icon="['fas', 'history']" />
                  </span>
                </span>
              </div>
              <div class="d-flex flex-column font-weight-bold">
                <span class="text-dark text-hover-primary">{{ format.sunbedHours(equipment.getControllerHours('unit') || 0) }}</span>
                <span class="text-muted font-size-xs">Sunbed hours</span>
              </div>
            </div>
            <!-- end::Sunbed hours -->
          </div>
        </div>
        <!-- end::Sunbed -->

        <!-- begin::Releases -->
        <div class="col-12 mt-3">
          <releases-row
            :equipment="equipment"
            :key="`equipment.${equipment._meta.guid}.releases`"
          />
        </div>
        <!-- end::Releases -->

        <!-- begin::Companies -->
        <div
          v-if="($auth.isResourceFromIndex('users') && $auth.hasPermission('companies.get'))"
          class="col-12 border-top pt-3 mt-3"
        >
          <linked-companies
            :equipment="equipment"
            :transform="transformCompanies"
          >
            <template slot="default" slot-scope="props">
              <!-- begin::Companies - for loop -->
              <div v-if="props.companies && props.companies.length > 0" class="row">
                <div
                  v-for="company in props.companies"
                  :key="`equipment.${equipment._meta.guid}.companies.${company._meta.guid}`"
                  class="col"
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-30 symbol-secondary mr-3">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-secondary">
                          <font-awesome-icon :icon="['fas', 'store-alt']" />
                        </span>
                      </span>
                    </div>
                    <div class="d-flex flex-column font-weight-bold">
                      <span class="text-dark text-hover-primary">{{ company.name }}</span>
                      <span class="text-muted font-size-xs">{{ $tu(`companies.type.${company.company_type}.name`) || company.company_type }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end::Companies - for loop -->

              <!-- begin::Companies - None found -->
              <div v-else class="row">
                <div class="col-12 alert alert-custom alert-light-info my-0 p-3">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-30 symbol-light-info mr-3">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-secondary">
                          <font-awesome-icon :icon="['fas', 'store-alt']" />
                        </span>
                      </span>
                    </div>

                    <div class="d-flex flex-column">
                      <span class="text-info">No companies linked</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end::Companies - None found -->
            </template>
          </linked-companies>
        </div>
        <!-- end::Companies -->
      </div>
    </template>
  </b-dropdown>
</template>

<script>
import format from '@/libs/format';
import { company as companyLib } from '@vedicium/core-vue/build/modules/company';

import releasesRow from './releases.row.vue'; // eslint-disable-line import/order
import linkedCompanies from '@/components/pages/sunbeds/view/components/linked-companies.vue';

import Equipment from '@/libs/classes/equipment';

export default {
  name: 'informationDropdown',
  components: {
    linkedCompanies,
    releasesRow,
  },
  props: {
    equipment: {
      type: Equipment,
      default: null,
    },
  },
  data () {
    return {
      isMounted: false,

      format,
    };
  },

  mounted () {
    this.$refs.dropdown.$on('shown', this.onShown);
    this.$refs.dropdown.$on('hide', this.onHide);
  },
  beforeDestroy () {
    this.$refs.dropdown.$off('shown', this.onShown);
    this.$refs.dropdown.$off('hide', this.onHide);
  },

  methods: {
    async onShown () {
      // Nothing to do here
    },

    onHide () {
      this.$set(this, 'isMounted', false);
    },

    transformCompanies (companies = []) {
      return (companies || []).filter((company) => company._meta.guid !== companyLib.current._meta.guid, []);
    },
  },
};
</script>
