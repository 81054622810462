<template>
  <!-- begin::Loading -->
  <div
    v-if="isLoading"
    class="row h-50px w-100"
  >
    <div class="col loading-container loading-overlap">
      <div class="loading-backdrop rounded" />
      <div class="loading-block">
        <div class="blockui mx-auto w-100">
          <span>Gathering data...</span>
          <span class="spinner spinner-primary ml-auto" />
        </div>
      </div>
    </div>
  </div>
  <!-- end::Loading -->

  <!-- begin::Releases -->
  <div
    v-else
    class="row"
  >
    <div class="col-12 d-flex align-items-center">
      <div :class="[
        'symbol symbol-30 mr-3',
        (software.release && software.release.hash
          ? (software.latest && software.latest._meta.guid === software.release.hash)
            ? 'symbol-light-success'
            : 'symbol-light-info'
          : 'symbol-secondary'
        )
      ]">
        <span class="symbol-label">
          <span class="svg-icon">
            <font-awesome-icon :icon="['fas', 'cogs']" />
          </span>
        </span>
      </div>
      <div class="d-flex flex-column font-weight-bold">
        <span class="text-dark text-hover-primary">
          {{ (software.release && software.release.version) || 'Unknown' }}
        </span>
        <span class="text-muted font-size-xs">Software version</span>
      </div>
    </div>
  </div>
  <!-- end::Releases -->
</template>

<script>
import Equipment from '@/libs/classes/equipment';
import Update from '@/libs/classes/update';

export default {
  name: 'releasesRow',
  props: {
    equipment: {
      type: Equipment,
      default: null,
    },
  },
  data () {
    return {
      isLoading: false,

      software: {
        release: (this.equipment && this.equipment.controllerRelease) || null,
        latest: null,
      },
    };
  },

  mounted () {
    // Gather latest software version, if set
    this.gatherLatestReleases();
  },

  methods: {
    async gatherLatestReleases () {
      this.$set(this, 'isLoading', true);

      const promises = [];
      if (this.software.release && this.software.release.hash) {
        promises.push((async () => {
          const response = await this.$ws.get(`${Update.uri}/${this.software.release.type}/latest`);
          this.$set(this.software, 'latest', new Update().merge(response));
        })());
      }

      try {
        if (promises.length > 0) {
          await Promise.all(promises);
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.$set(this, 'isLoading', false);
      }
    },
  },
};
</script>
