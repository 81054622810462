<template>
  <b-modal
    ref="modal"

    :title="(options.title || 'Connect sunbed')"

    size="lg"
    centered
  >
    <template v-slot:default>
      <div class="row d-none d-md-flex">
        <div class="col-auto mx-auto">
          <img :src="require('@/assets/images/module/myluxura-module.sticker.svg')" class="img-fluid" alt="MyLuxura Module">
        </div>
      </div>

      <form class="form" @submit.prevent="submitForm">
        <div class="form-group">
          <label>Module ID <sup>Required</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i>
                  <font-awesome-icon :icon="['fas', 'cube']" />
                </i>
              </span>
            </div>
            <input
              ref="serialInput"
              type="text"
              :class="['form-control', { 'is-invalid': $v.form.serial.$error }]"
              :disabled="isLoadingForm"
              placeholder="MLXXXXXXXXXXXXXX"
              v-model.trim="$v.form.serial.$model"
            />
          </div>
          <span class="form-text text-muted">The Module ID as printed on the included label.</span>
        </div>

        <div class="form-group">
          <label>Module password <sup>Required</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i>
                  <font-awesome-icon :icon="['fas', 'key']" />
                </i>
              </span>
            </div>
            <input
              type="password"
              :class="['form-control', { 'is-invalid': $v.form.psk.$error }]"
              :disabled="isLoadingForm"
              placeholder=""
              v-model.trim="$v.form.psk.$model"
            />
          </div>
          <span class="form-text text-muted">The Module password as printed on the included label.</span>
        </div>
      </form>
    </template>

    <template v-slot:modal-footer>
      <button type="button" class="btn btn-secondary" :disabled="isLoadingForm" @click.prevent="close">Cancel</button>
      <button type="submit" class="btn btn-primary" :disabled="isLoadingForm" @click.prevent="submitForm">
        Connect
      </button>
    </template>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { validations } from '@vedicium/core-vue';
import { Mixins } from '@vedicium/metronic-vue';

import { CompanyClass as Company, company as companyLib } from '@vedicium/core-vue/build/modules/company';

export default {
  mixins: [Mixins.Modal],
  name: 'connectSunbedModal',
  data () {
    return {
      options: {},
      isLoadingForm: false,

      form: {
        serial: null,
        psk: null,
      },
    };
  },
  validations: {
    form: {
      serial: {
        required,
      },
      psk: {
        required,
      },
    },
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:equipment:connect-sunbed:open', this.open);
    this.$eventhub.on('modals:equipment:connect-sunbed:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:equipment:connect-sunbed:open', this.open);
    this.$eventhub.off('modals:equipment:connect-sunbed:close', this.close);
  },

  methods: {
    async validate () {
      await validations.validateArray([this.$v]);
    },
    async submitForm () {
      this.$set(this, 'isLoadingForm', true);

      let response = null;
      try {
        await this.validate();

        response = await this.$ws.post(`${Company.uri}/${companyLib.current._meta.guid}/equipment/connect`, {
          body: {
            module: this.form.serial,
            module_psk: this.form.psk,
          },
        });

        if (this.options && typeof this.options.onSubmit === 'function') {
          await this.options.onSubmit(response);
        }
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { ui_element: true });
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.close();
    },

    open (options = {}) {
      // Reset options
      this.$set(this, 'options', options || {});

      // Reset form
      this.$set(this.form, 'serial', this.options.serial || null);
      this.$set(this.form, 'psk', this.options.psk || null);
      this.$v.$reset();

      this.$refs.modal.show();
    },
    close () {
      this.$refs.modal.hide();
    },

    async onShown () {
      await this.$nextTick();

      this.$refs.serialInput.focus();
    },
  },
};
</script>
