<template>
  <div>
    <!-- begin::Loading -->
    <template v-if="isMounted === false || isLoading">
      <div class="position-relative h-50px w-100">
        <div class="loading-container loading-overlap">
          <div class="loading-backdrop rounded" />
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span class="float-left">Gathering data...</span>
              <span class="spinner spinner-primary ml-auto" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- end::Loading -->

    <!-- begin::Error -->
    <template v-else-if="isErrorActive">
      <div class="loading-container loading-overlap">
        <div class="loading-block w-75">
          <error-translation-alert
            ref="errorTranslationAlert"
            :error="activeError"
            class="shadow"
          />
        </div>
      </div>
    </template>
    <!-- end::Error -->

    <!-- begin::Companies -->
    <template v-else>
      <slot name="default" v-bind="{ companies }" />
    </template>
    <!-- end::Companies -->
  </div>
</template>

<script>
import { errorComponentMixin } from '@vedicium/core-vue';

import Equipment from '@/libs/classes/equipment';

import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default {
  mixins: [errorComponentMixin],
  components: {
    errorTranslationAlert,
  },
  props: {
    equipment: {
      type: Equipment,
      default: null,
    },
    transform: {
      type: Function,
      default: null,
    },
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      companies: [],
    };
  },

  async mounted () {
    try {
      this.$set(this, 'companies', await this.getLinkedCompanies());
    } catch (e) {
      this.$errors.handle(e, { component: this, ui_element: false });
      console.error(e);
      return;
    } finally {
      this.$set(this, 'isMounted', true);
    }
  },

  methods: {
    async getLinkedCompanies (options = {}) {
      this.$errors.clear({ component: this });
      this.$set(this, 'isLoading', true);

      let companies = null;
      try {
        companies = await this.equipment.getLinkedCompanies(options);

        if (typeof this.transform === 'function') {
          companies = this.transform(companies);
        }
      } finally {
        this.$set(this, 'isLoading', false);
      }

      return companies;
    },
  },
};
</script>
